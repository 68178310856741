var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTView',[_c('VRow',[_c('VCol',[_c('VBreadcrumbs',{staticClass:"pl-0",attrs:{"items":_vm.breadcrumbs}})],1)],1),_c('VRow',[_c('VCol',[_c('h1',{staticClass:"tt-text-headline-1"},[_vm._v(" "+_vm._s(_vm.group.name || '-')+" ")])])],1),_c('VRow',{staticClass:"text-body-2"},[_c('VCol',{attrs:{"cols":"2"}},[_c('span',{staticClass:"tt-light-mono-46--text"},[_vm._v("Компания")])]),_c('VCol',[_vm._v(" "+_vm._s(_vm.group.companyId || '-')+" ")])],1),_c('VRow',{staticClass:"text-body-2"},[_c('VCol',{attrs:{"cols":"2"}},[_c('span',{staticClass:"tt-light-mono-46--text"},[_vm._v("Команда")])]),_c('VCol',[_vm._v(" "+_vm._s(_vm.group.teamId || '-')+" ")])],1),_c('VRow',{staticClass:"text-body-2"},[_c('VCol',{attrs:{"cols":"2"}},[_c('span',{staticClass:"tt-light-mono-46--text"},[_vm._v("Пользователь")])]),_c('VCol',[_vm._v(" "+_vm._s(_vm.group.userId || '-')+" ")])],1),_c('VRow',[_c('VCol',[_c('VDivider')],1)],1),_c('VRow',[_c('VCol',[_c('h2',{staticClass:"tt-text-title-2"},[_vm._v(" Процедуры ")])])],1),(_vm.procedures.length > 0)?[_c('VRow',[_c('VCol',[_c('TTDataTable',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.procedures},on:{"click:row":_vm.handleProcedureClick},scopedSlots:_vm._u([{key:"item.steps",fn:function(ref){
var procedure = ref.item;
return [_c('VChipGroup',_vm._l((procedure.steps),function(step){return _c('VTooltip',{key:step.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VChip',_vm._g(_vm._b({attrs:{"small":""}},'VChip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.pruneUuid(step.name))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(step.name))])])}),1)]}},{key:"item.state",fn:function(ref){
var procedure = ref.item;
return [_c('VChip',{attrs:{"small":""}},[_vm._v(" "+_vm._s(procedure.state)+" ")])]}}],null,false,1532214737)})],1)],1),_c('VRow',[_c('VCol',[_c('TTBtn',{attrs:{"to":{ name : _vm.Names.R_LEARNING_ASSESSMENT_PROCEDURE_CREATE },"color":"tt-ghost","plain":""}},[_c('VIcon',{attrs:{"left":""}},[_vm._v(" fal fa-plus ")]),_vm._v(" Добавить процедуру ")],1)],1)],1),_c('VRow',[_c('VCol',[_c('VPagination',{attrs:{"circle":"","disabled":_vm.loading,"length":_vm.totalPages,"total-visible":_vm.pagination.limit},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)]:(!_vm.loading)?[_c('VRow',[_c('VCol',{attrs:{"align":"center","justify":"center"}},[_c('VImg',{attrs:{"width":"102","src":require('@/assets/male-mexican-hat.png')}}),_c('div',[_vm._v(" Нет процедур ")]),_c('RouterLink',{attrs:{"to":{ name : _vm.Names.R_LEARNING_ASSESSMENT_PROCEDURE_CREATE }}},[_vm._v(" Создать процедуру ")])],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }