<template>
  <TTView>
    <VRow>
      <VCol>
        <VBreadcrumbs
          class="pl-0"
          :items="breadcrumbs"
        />
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <h1 class="tt-text-headline-1">
          {{ group.name || '-' }}
        </h1>
      </VCol>
    </VRow>

    <VRow class="text-body-2">
      <VCol cols="2">
        <span class="tt-light-mono-46--text">Компания</span>
      </VCol>

      <VCol>
        {{ group.companyId || '-' }}
      </VCol>
    </VRow>

    <VRow class="text-body-2">
      <VCol cols="2">
        <span class="tt-light-mono-46--text">Команда</span>
      </VCol>

      <VCol>
        {{ group.teamId || '-' }}
      </VCol>
    </VRow>

    <VRow class="text-body-2">
      <VCol cols="2">
        <span class="tt-light-mono-46--text">Пользователь</span>
      </VCol>

      <VCol>
        {{ group.userId || '-' }}
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <VDivider />
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <h2 class="tt-text-title-2">
          Процедуры
        </h2>
      </VCol>
    </VRow>

    <template v-if="procedures.length > 0">
      <VRow>
        <VCol>
          <TTDataTable
            :loading="loading"
            :headers="headers"
            :items="procedures"
            @click:row="handleProcedureClick"
          >
            <template #item.steps="{ item : procedure }">
              <VChipGroup>
                <VTooltip
                  v-for="step in procedure.steps"
                  :key="step.id"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <VChip
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ pruneUuid(step.name) }}
                    </VChip>
                  </template>
                  <span>{{ step.name }}</span>
                </VTooltip>
              </VChipGroup>
            </template>

            <template #item.state="{ item : procedure }">
              <VChip
                small
              >
                {{ procedure.state }}
              </VChip>
            </template>
          </TTDataTable>
        </VCol>
      </VRow>

      <VRow>
        <VCol>
          <TTBtn
            :to="{ name : Names.R_LEARNING_ASSESSMENT_PROCEDURE_CREATE }"
            color="tt-ghost"
            plain
          >
            <VIcon left>
              fal fa-plus
            </VIcon>

            Добавить процедуру
          </TTBtn>
        </VCol>
      </VRow>

      <VRow>
        <VCol>
          <VPagination
            v-model="pagination.page"
            circle
            :disabled="loading"
            :length="totalPages"
            :total-visible="pagination.limit"
          />
        </VCol>
      </VRow>
    </template>
    <template v-else-if="!loading">
      <VRow>
        <VCol
          align="center"
          justify="center"
        >
          <VImg
            width="102"
            :src="require('@/assets/male-mexican-hat.png')"
          />

          <div>
            Нет процедур
          </div>

          <RouterLink :to="{ name : Names.R_LEARNING_ASSESSMENT_PROCEDURE_CREATE }">
            Создать процедуру
          </RouterLink>
        </VCol>
      </VRow>
    </template>
  </TTView>
</template>

<script>
import { pruneUuid as pruneUuidUtil } from '@front.backoffice/common';

export default {
  name: 'GroupsView',

  inject: ['Names'],

  props: {
    accountId: {
      type: String,
      required: true,
    },
    companyId: {
      type: String,
      required: true,
    },
    groupId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      group: {},
      company: {},
      account: {},
      headers: [
        {
          text: 'Название процедуры',
          value: 'name',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Компетенция',
          value: 'competencyId',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Навык',
          value: 'skillId',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Шаги',
          value: 'steps',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Состояние',
          value: 'state',
          sortable: false,
          width: '20%',
        },
      ],
      procedures: [],
      pagination: {
        page: 1,
        limit: 5,
        total: 0,
      },
    };
  },

  computed: {
    breadcrumbs() {
      const groupName = this.group.name ? this.group.name : '-';

      const breadcrumbs = [
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW }, exact: true, text: this.account.name },
        { to: { name: this.Names.R_ACCOUNT_V2_COMPANY_VIEW }, exact: true, text: this.company.name },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_GROUPS }, exact: true, text: 'Группы оценки' },
        { to: { name: this.Names.R_LEARNING_ASSESSMENT_GROUPS_VIEW }, exact: true, text: groupName },
      ];

      return breadcrumbs;
    },

    totalPages() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
  },

  watch: {
    groupId: {
      handler: 'fetch',
    },

    'pagination.page': async function handler() {
      await this.fetch();
    },
  },

  created() {
    this.fetch();
    this.getCompanyAndAccount();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const { groupId } = this;
        const app = new this.$di.ddd.Assessment();
        const [group] = await app.services.assessment.fetchGroup({ groupId });
        const [{ procedures, pagination }] = await app.services.assessment.fetchGroupProcedures({
          groupId,
          ...this.pagination,
        });
        this.group = group;
        this.procedures = procedures;
        this.pagination = {
          page: Number(pagination.page),
          limit: Number(pagination.limit),
          total: Number(pagination.total),
        };
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async getCompanyAndAccount() {
      try {
        const { companyId, accountId } = this;

        const accountsApp = new this.$di.ddd.Accounts();
        const [account] = await accountsApp.services.account.getAccount({ id: accountId });
        const [company] = await accountsApp.services.account.getCompany({ id: companyId });

        this.account = account;
        this.company = company;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      }
    },

    handleProcedureClick(item) {
      this.$router.push({
        name: this.Names.R_LEARNING_ASSESSMENT_PROCEDURE_VIEW,
        params: {
          procedureId: `${item.id}`,
        },
      });
    },

    pruneUuid(uuid) {
      return pruneUuidUtil(uuid, 6);
    },
  },
};
</script>
